import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import { formatTimeUntilNow } from '@/lib/date';
import { SharpProcessedImage } from '@/lib/graphql';
import { graphql, Link, PageProps } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import React, { FC } from 'react';

import { BlogPageQuery, BlogPost, Frontmatter } from '../../../graphql-types';
import './style.css';

type BlogPostBoxProps = Pick<
  BlogPost,
  'path' | 'title' | 'timeToRead' | 'excerpt'
> & {
  image?: SharpProcessedImage;
  date: any;
  author: Pick<Frontmatter, 'name'> & {
    image?: SharpProcessedImage;
  };
};

const BlogPostBox: FC<BlogPostBoxProps> = ({
  path,
  image,
  title,
  author,
  date,
  timeToRead,
  excerpt,
}) => (
  <Link
    className="BlogPostCard transition-all transform scale-100 hover:scale-105 shadow hover:shadow-2xl"
    to={path}
  >
    <div className="no-underline flex flex-col lg:flex-row relative">
      <div className="lg:flex-1 mb-6 -mx-6 lg:-my-10 lg:mr-0">
        {image && (
          <BackgroundImage
            Tag="div"
            className="blog-post-image"
            {...convertToBgImage(getImage(image as any))}
          >
            {' '}
          </BackgroundImage>
        )}
      </div>

      <div className="lg:flex-1 lg:ml-4">
        {author && (
          <div className="flex mb-4">
            {author.image && (
              <GatsbyImage
                alt={author.name}
                className="rounded-full mr-4"
                image={getImage(author.image as any)}
              />
            )}
            <div>
              <p className="text-2xl text-primary-200 leading-tight">
                {author.name}
              </p>
              <p>
                {formatTimeUntilNow(date)}
                {` - ${timeToRead} min read`}
              </p>
            </div>
          </div>
        )}
        <h2 className="card-header">{title}</h2>
        <p>{excerpt}</p>
        <Link className="block mt-4" to={path}>
          Read more...
        </Link>
      </div>
    </div>
  </Link>
);

const BlogPage: FC<PageProps<BlogPageQuery>> = ({ data: { blogPosts } }) => {
  return (
    <Layout>
      <SEO url="blog" title="OptiOffer's blog" description="OptiOffer's blog" />
      <div className="container p-4 m-auto">
        <section className="pb-12 lg:pb-24">
          <h1 className="header-1 text-left pt-24 lg:pt-32 pb-4">Our Blog</h1>
          <p>A place of inspiration on how to improve your business.</p>
        </section>
        <section className="flex flex-col mb-20">
          {blogPosts.edges.map(
            ({
              node: {
                path,
                image,
                title,
                publishedDate,
                timeToRead,
                author,
                excerpt,
              },
            }) => (
              <BlogPostBox
                key={path}
                path={path}
                image={image}
                title={title}
                date={publishedDate}
                timeToRead={timeToRead}
                author={author?.frontmatter}
                excerpt={excerpt}
              />
            )
          )}
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query BlogPage {
    blogPosts: allBlogPost(sort: { fields: [publishedDate], order: DESC }) {
      edges {
        node {
          excerpt
          timeToRead
          title
          path
          publishedDate
          author {
            frontmatter {
              name
              image {
                childImageSharp {
                  gatsbyImageData(
                    width: 60
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          image {
            childImageSharp {
              gatsbyImageData(
                width: 500
                # https://github.com/gatsbyjs/gatsby/issues/12552
                # placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`;

export default BlogPage;
